import { useRouter } from 'next/router'
import { Google } from 'ui/assets'
import { loginWithGoogle } from '../config/firebase'

const Login = () => {
  const { push } = useRouter()
  return (
    <div className="bg-gradient-to-r from-indigo-700 to-indigo-800">
      <div className="flex min-h-screen flex-col items-center justify-center">
        <div className="flex flex-col items-center p-8">
          <div className="rounded-lg">
            <div className="rounded-full bg-[#ffffff1a] p-4">
              <div className=" rounded-full bg-[#ffffff30] p-8">
                <img
                  src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/zmhh5jhcqmym7ehxhabh"
                  alt="Trademarkia"
                  className="h-36 w-36"
                />
              </div>
            </div>
          </div>
          <h1 className="mt-6 text-center text-5xl font-bold text-white">
            Trademarkia
            <span className="font-normal text-indigo-300">Admin</span>
          </h1>
          <p className="my-4 text-center text-lg text-white">
            Please login to continue
          </p>
          <div className="flex justify-center">
            <button
              type="button"
              onClick={async () =>
                loginWithGoogle().then(res => res.success && push('/tm/'))
              }
              className="mt-8 flex items-center justify-center gap-2 self-stretch rounded-xl border border-black bg-white p-3"
            >
              <Google className="h-8 w-8" />
              <span>Login with Google</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
